import * as React from "react";
import { Link } from "gatsby";
import HeaderMenu from "../components/HeaderMenu";
import { withLayout, menuItems } from "../components/Layout";
import { Bem } from "../utils/bem";
import "./error-page.scss";

const NotFoundPage = (props: any) => {
  const page = new Bem("l-error-page");
  return (
    <React.Fragment>
      <HeaderMenu pathname={props.location.pathname} items={menuItems} />
      <section className={page.with()}>
        <div className={page.with("wrapper")}>
          <h1 className={page.with("title")}>Error 404</h1>
          <p className={page.with("message")}>Oh no! It looks like the page you was looking for was not found.</p>
          <Link to="/" className={page.with("link")}>Go back Home</Link>
        </div>
      </section>
    </React.Fragment>
  );
};

export default withLayout(NotFoundPage);
